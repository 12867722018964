import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import './style.css'
import Auth0Icon from './Assets/badge.png'



const MFAPromtPage = () => {
    const { user } = useAuth0();
    // const navigate = useNavigate();
    const { isAuthenticated, getIDTokenClaims } = useAuth0();
    const { skipMfa, setSkipMfa } = useState(false);
    // const location = useLocation(); 
    const [stateParam, setStateParam] = useState(''); 

    
    
    
    const handleSkipMfa = () => {
        if (stateParam !== undefined) {
            let url = 'https://testtenantpasswordlessauthentication.us.auth0.com/continue?state=' + stateParam +'&isMfa=none' 
            window.location.href = url;
        }
        // navigate('/dashboard');
    };

    const handleIDProofing = () => {
        // if (stateParam !== undefined) {
        //     let url = 'https://testtenantpasswordlessauthentication.us.auth0.com/continue?state=' + stateParam +'&isMfa=phone'
        //     window.location.href = url;
        // }
        // navigate('/mfa-setup');
    };
    const handleAuthenticatorEnroll = () => {
        if (stateParam !== undefined) {
            let url = 'https://testtenantpasswordlessauthentication.us.auth0.com/continue?state=' + stateParam +'&isMfa=guardian'
            window.location.href = url;
        }
        // navigate('/mfa-setup');
    };
    const handleTotpEnroll = () => {
        if (stateParam !== undefined) {
            let url = 'https://testtenantpasswordlessauthentication.us.auth0.com/continue?state=' + stateParam +'&isMfa=otp'
            window.location.href = url;
        }
        // navigate('/mfa-setup');
    };


    useEffect(() => {
        
        const urlParams = new URLSearchParams(window.location.search);  
        const state = urlParams.get('state');  
        setStateParam(state);
        console.log(state);
        console.log(stateParam);
        }, [window.location])
        
        // const fetchData = async () => {
        //     if (isAuthenticated) {
        //         const idToken = await getIDTokenClaims();
        //         const requiresMFA = idToken?.['https://testtenantpasswordlessauthentication.us.auth0.com/mfa_required'] || false;
        //         setEnrollMFA(requiresMFA);
        //     }
        // };
        // fetchData();
   
    
    return (
      
        <div className="auth-widget">
            <img src={ Auth0Icon } alt="Auth0 Icon" className="auth-icon" />
           <p>Choose the MFA you want to Opt</p>
              <div>
                  {/* <h2>MFA Enrollment Required </h2> */}
                  <button onClick={handleAuthenticatorEnroll}>TOTP Authenticators</button>
                  <button onClick={handleTotpEnroll}>Phone Message</button>
                  {/* <button onClick={handleMfaEnroll}>Auth0 Guardian</button> */}
                  <button onClick={handleIDProofing}>ID Proofing</button>
                  <button onClick={handleSkipMfa}>Skip MFA</button>
              </div>
          
                  
                  
          
            </div>
        
  )
}

export default MFAPromtPage;