import './App.css';
// import Login from './Login';
import MFAPromtPage from './MFAPromtPage';
// import './Assets'

function App() {
  return (
    <>
      <MFAPromtPage/>
      {/* <Login/> */}
    </>
  );
}

export default App;
